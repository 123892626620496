import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BiExit } from "react-icons/bi";
import ww from "./vozn.jpg";
import berdsk from "./Berdsk.jpg";
import hjk from "./xxl.jpg";
import hh1 from "./img_hram/vozn.jpg";
import mm1 from "./img_hram/karta.png";
import hh2 from "./img_hram/al-nevsk.jpg";
import hh5 from "./img_hram/georgiy.jpg";
import hh3 from "./img_hram/Sv_Nikolay.jpg";
import hh6 from "./img_hram/evgeniy.jpg";
import hh4 from "./img_hram/apostol-andrey.jpg";
import hh7 from "./img_hram/kazansk.jpg";


import hh8 from "./img_hram/abalackaya.jpg";
import hh9 from "./img_hram/arh-mihail.jpg";
import hh10 from "./img_hram/nikolay.jpg";
import hh11 from "./img_hram/Panteleimon.jpg";
import hh12 from "./img_hram/troice-vladim.jpg";
import hh13 from "./img_hram/pokrov.jpg";
import hh14 from "./img_hram/russkih-sv2.jpg";
import hh15 from "./ImageEpnsk/2.jpg";
import hh16 from "./img_hram/pashino.jpg";
import hh17 from "./ImageEpnsk/2.5.jpg";
import hh18 from "./ImageEpnsk/2.1.jpg";
import hh19 from "./ImageEpnsk/2.3.jpg";
import hh20 from "./img_hram/georgy-2.jpg";
import hh21 from "./img_hram/pashino-2.jpg";
import hh22 from "./ImageEpnsk/3.2.jpg";
import hh23 from "./img_hram/vasiliy.jpg" ;
import hh24 from "./ImageEpnsk/3.3.jpg";
import hh25 from "./ImageEpnsk/3.1.jpg";
import hh26 from "./img_hram/vasiliy_2.jpg" ;
import hh27 from "./ImageEpnsk/1.9.jpg";
import hh28 from "./ImageEpnsk/1.3.jpg";
import hh29 from "./ImageEpnsk/hram_vvedenskiy.jpg";


import hh30 from "./ImageEpnsk/1.2.jpg";
import hh31 from "./ImageEpnsk/1.1.jpg";
import hh32 from "./ImageEpnsk/1.5.jpg";
import hh33 from "./ImageEpnsk/1.6.jpg";
import hh34 from "./ImageEpnsk/1.8.jpg";
import hh35 from "./ImageEpnsk/1.4.jpg";
import hh36 from "./ImageEpnsk/1.11.jpg";

import hh37 from "./ImageEpnsk/4.2.jpg";
import hh38 from "./ImageEpnsk/4.1.jpg";
import hh39 from "./ImageEpnsk/4.4.jpg";
import hh40 from "./ImageEpnsk/4.3.jpg";
import hh41 from "./ImageEpnsk/4.6.jpg";
import hh42 from "./ImageEpnsk/4.5.jpg";

function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
      <p></p>
      <div className="alle3" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Храмы Новосибирска </b>
      </div>

      <div className="inf-link">
        <Link to="/MapMain">
          <div>
            {" "}
            <img src={mm1} width={140} alt="карта" title="Храм" />
          </div>
        </Link>
      </div>

      {/* Вознесенский */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Кафедральный собор в честь Вознесения Господня</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh1}
              width={350}
              alt="Кафедральный собор в честь Вознесения Господня"
              title="Храм"
            />
          </div>
          <div class={"inf-4"}>
            Первый деревянный храм в честь Вознесения Господня был построен в
            городе Ново-Николаевске в 1913 году.
            <div>
              {" "}
              В 1924 году церковь стала кафедральным собором, в 1937 году он был
              закрыт и в нём разместили зернохранилище. В 1944 году трудами
              митрополита Варфоломея он был возвращён епархии. В 1970—1980 годы
              собор был перестроен в камне. Вознесенский кафедральный собор —
              главный храм епархии. Здесь располагается кафедра правящего
              архиерея. Верхний имеет три придела: в честь Вознесения Христова,
              Николая Чудотворца и Серафима Саровского, нижний храм освящён во
              имя Александра Невского и праотца Гедеона.{" "}
            </div>
            <p>
              <Link
                to="/MapMain"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <b>Адрес: </b>г. Новосибирск, ул. Советская, 91.
              </Link>
            </p>
          </div>
        </table>
      </div>

      {/* св. Александра Невского */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Собор во имя святого благоверного князя Александра Невского</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh2}
              width={350}
              alt="Собор св. Александра Невского"
              title="Храм"
            />
          </div>
          <div class={"inf-4"}>
            Закладка Александро-Невского храма состоялась 15 мая 1897 года,
            строительство проводилось под руководством инженера-путейца
            Н.Н. Тихомирова. Храм стал первым каменным общественным зданием
            города. 29 декабря 1899 года он был освящен епископом Томским и
            Барнаульским Макарием (Невским). В 1915 году храм получил статус
            собора.
            <div>
              {" "}
              В 1938 году постановлением президиума Новосибирского горсовета
              собор был закрыт. Храм хотели взорвать, однако заряды разрушали
              лишь подвальные перегородки, мощные же стены собора оставались
              невредимы. В конце концов от идеи разрушения храма пришлось
              отказаться, так как взрывы могли серьезно повредить расположенные
              рядом здания советских учреждений.
            </div>{" "}
            <div>
            25 августа 1989 года Александро-Невский собор был передан мэрией в пользование 
            Новосибирской епархии и начались восстановительные работы. 15 мая 1991 года 
            возрожденный собор был освящен Святейшим Патриархом Московским и всея Руси Алексием II. 
            В собственность Церкви собор был возвращен в 2012 году. 20 декабря губернатор 
            В.А.Юрченко передал митрополиту Новосибирскому и 
            Бердскому Тихону документы на здание собора и земельный участок.
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Красный проспект, 1а.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Покровский храм */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Покрова Пресвятой Богородицы</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh13}
              width={350}
              alt="Храм в честь Покрова Пресвятой Богородицы"
              title="Храм в честь Покрова Пресвятой Богородицы"
            />
          </div>
          <div class={"inf-4"}>
            Храм в честь Покрова Пресвятой Богородицы был заложен 16 мая 1901
            года (29 мая по новому стилю) на пересечении улиц Болдыревской и
            Гондатти (ныне Октябрьская и Урицкого), на уже намоленном месте, так
            как здесь с ноября 1895 года размещался молитвенный дом.
            <div>
              {" "}
              10 декабря 1901 года был совершён чин освящения. Храм стал третьей
              церковью Новониколаевска после возведения храма во имя святого
              пророка Даниила (1898 г.) и собора во имя святого благоверного
              князя Александра Невского (1899 г.).
            </div>{" "}
            <div>
              В 1906 г на средства местных купцов братьев Маштаковых Федора,
              Гавриила, Павла и Григория церковь была значительно расширена:
              пристроена трапезная, притвор, колокольня и крытые сени. В
              цокольной части храма устроен полуподвальный этаж.
            </div>
            <div>
              {" "}
              Покровский храм с момента его основания был центром
              благотворительности. 28 мая 1906 г. в здании церковно-приходской
              школы был открыт приют «Ясли» для постоянного призрения
              бесприютных детей и для дневного ухода за малолетними детьми
              матерей, выходящих на работу.
            </div>
            <div>
              Наступили времена гонений, 8 марта 1938 года с колокольни
              Покровской церкви были сняты колокола, а 4 августа 1939 года по
              постановлению Новосибирского облисполкома храм закрыли. С тех пор
              здание занимали различные учреждения.
            </div>
            <div>
              Вновь приход в честь Покрова Пресвятой Богородицы образован 20
              апреля 1992 года. В 1993 году здание храма было передано
              Новосибирской епархии, и с этого времени началось его
              восстановление.
              <div>
              На праздник Покрова Богородицы в 1994 году было освящение 
              храма, в нем вновь стали совершаться службы. А в 2009 году
                завершилось полная реконструкция храма и он приобрел свой
                исторический облик.
              </div>
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Октябрьская, 9.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Троице-Владимирский собор*/}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Троице-Владимирский собор</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh12}
              width={350}
              alt="Троице-Владимирский собор"
              title="Троице-Владимирский собор"
            />
          </div>
          <div class={"inf-4"}>
            Приход во имя святого равноапостольного князя Владимира в
            Новосибирске был основан в 1997 году. 24 мая 2002 года Святейший
            Патриарх Алексий II подписал грамоту на основание и начало
            строительства собора.
            <div>
              На Пасху 2003 года в нижнем храме во имя Святой Троицы была
              совершена первая Божественная Литургия. А в 2008 году состоялось
              освящение нижнего храма.
              <div>
                {" "}
                24 августа 2013 года, в ходе Первосвятительского визита в
                Новосибирскую митрополию, Святейший Патриарх Московский и всея
                Руси Кирилл совершил чин великого освящения верхнего храма
                святого равноапостольного князя Владимира и Божественную
                литургию в новоосвященном храме.
              </div>{" "}
              <div>
                При соборе учреждена и действует Православная гимназия во имя
                святого равноапостольного князя Владимира.
              </div>
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Филатова, 14а.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* храм Новомучеников*/}
      {/* <div class="church-1">
                    <table>
                        <div class="church-1-title">
                            <p>Храм Новомучеников и исповедников Церкви Русской</p>
                        </div>
                        <div class="church-1-image">
                            <img src={hh12} width={350} 
                            alt="Храм Новомучеников и исповедников Церкви Русской"
                                title="Храм Новомучеников и исповедников Церкви Русской" />
                        </div>
                        <div class ={"inf-4"}>
                        Приход во имя святого равноапостольного князя Владимира в Новосибирске 
                        был основан в 1997 году. 24 мая 2002 года Святейший Патриарх Алексий II 
                        подписал грамоту на основание и начало строительства собора. 
                        <div>
                           На Пасху 2003 года в нижнем храме во имя Святой Троицы 
                           была совершена первая Божественная Литургия. А в 2008 году состоялось освящение нижнего храма.
                           <div> 24 августа 2013 года, в ходе Первосвятительского визита в Новосибирскую митрополию, 
                           Святейший Патриарх Московский и всея Руси Кирилл совершил чин великого освящения верхнего храма
                            святого равноапостольного князя Владимира и Божественную литургию в новоосвященном храме. 
                          
                          </div> <div>При соборе учреждена и действует Православная гимназия
                             во имя святого равноапостольного князя Владимира.</div></div>
                          
                         <p><b>Адрес:</b> г. Новосибирск, ул. Филатова, 14а. </p> 
                         </div>
                    </table>
                    </div>  */}

      {/* св. ап. Андрея */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святого апостола Андрея Первозванного </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh4}
              width={350}
              alt="Храм во имя святого апостола Андрея Первозванного "
              title="Храм во имя святого апостола Андрея Первозванного "
            />
          </div>
          <div class={"inf-4"}>
            12 мая 2012 года состоялось освящение закладного камня в основание
            храма во имя святого Андрея Первозванного в микрорайоне «Родники».
            24 июня 2017 года новосооруженный храм был освящен. Освящение
            нижнего храма во имя святителя Спиридона Тримифунтского состоялось
            30 апреля 2016 года.
            <div>
              У нового храма в Родниках самая большая в Сибири звонница с
              девятью колоколами, которые специально отливали в Воронеже. Самый
              большой колокол весит 640 кг.
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Тюленина 1а.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* мученика Евгения */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святого мученика Евгения</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh6}
              width={350}
              alt="Храм во имя святого великомученика Георгия Победоносца "
              title="Храм во имя святого великомученика Георгия Победоносца "
            />
          </div>
          <div class={"inf-4"}>
            5 ноября 1992 года состоялось освящение закладного камня в основание
            храма вблизи Заельцовского кладбища г. Новосибирска. 28 июня 1998
            года было совершено торжественное освящение престола во имя святого
            мученика Евгения. Храм построен в утешение матери, потерявшей своего единственного сына,
             и освящен 
            во имя его небесного покровителя. Строительство осуществлялось на её пожертвования.
            <div>
              {" "}
              С января 2001 года при храме расположено подворье
              Михаило-Архангельского женского монастыря с. Мало-Ирменка.
            </div>
            <div></div>
            <p>
              <b>Адрес:</b> г. Новосибирск, Мочищенское шоссе 1.
            </p>
          </div>
        </table>
      </div>

      {/* Архангела Михаила */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя Архистратига Божия Михаила в Иоанно-Предтеченском мужском монастыре</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh9}
              width={350}
              alt="Храм во имя Архистратига Божия Михаила"
              title="Храм во имя Архистратига Божия Михаила"
            />
          </div>
          <div class={"inf-4"}>
            В 1996 году в районе ОбьГЭС состоялось освящение закладного камня в
            фундамент храма во имя Архистратига Михаила — в память ранее
            существовавшей на этом месте церкви. 29 июня 2003 года храм был
            освящен. В цокольном этаже находится придел Марии Магдалины.
            <div>
              В 2001 году к еще строящейся церкви перевели монастырь святого
              мученика Евгения, который в 2007 году был переименован в
              Иоанно-Предтеченский. В 2000-х годах выстроены монастырская ограда
              с башнями, корпуса келий.
            </div>
            <div></div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Приморская 7.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* св. Николая */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святителя Николая Чудотворца </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh10}
              width={350}
              alt="Храм во имя святителя Николая Чудотворца "
              title="Храм во имя святителя Николая Чудотворца"
            />
          </div>
          <div class={"inf-4"}>
            Приход во имя Святителя Николая Чудотворца в микрорайоне «Нижняя
            Ельцовка» Советского района Новосибирска образован в 1998 году. Под
            устройство храма приходу было передано деревянное здание на
            территории Алтае-Саянской геодезической сейсмологической экспедиции,
            которое готовилось к списанию. Своими силами прихожане произвели
            ремонт и реконструкцию здания.
            <div>
              {" "}
              11 сентября 1998 года состоялось освящение храма. Впоследствии
              здание многократно достраивалось и перестраивалось, в частности
              были построены северный придел в честь святых Царственных
              Страстотерпцев, притвор с шатровой колокольней, трапезная,
              крестильня.
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Зоологическая 8а.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* наш - Русских святых */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Всех святых в земле Русской просиявших </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh14}
              width={350}
              alt="Храм всех русских святых"
              title="Храм всех русских святых"
            />
          </div>
          <div class={"inf-4"}>
            Приход в новосибирском Академгородке существует с декабря 1989 года.
            Строительство храма началось в августе 1990 года. Храм был освящен
            перед Пасхой (в Лазареву субботу) 1991 года. С тех пор в храме
            регулярно совершаются Богослужения. 
            <div>
              
              14 мая 1991 года храм посетил Святейший Патриарх Московский и всея
              Руси Алексий II во время своего первого визита в Новосибирск.
              Святейший Патриарх благословил планы и начинания нового прихода в
              области духовно-просветительного и социально-благотворительного
              служения.
              <div>
                В 1992 году приходом учреждена Православная гимназия во имя
                преподобного Сергия Радонежского.{" "}
              </div>
            </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Терешковой, 35.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Абалацкой иконы */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Абалацкой иконы Божией Матери</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh8}
              width={350}
              alt="Храм в честь Абалацкой иконы Божией Матери"
              title="Храм в честь Абалацкой иконы Божией Матери"
            />
          </div>
          <div class={"inf-4"}>
            В 1992 году зарегистрирован приход в честь иконы Божией Матери
            «Знамение-Абалацкая». Через два года состоялось освящение закладного
            камня на месте будущего храма. 25 июля 2000 года совершен чин
            полного освящения храма. Нижний храм был освящен во имя святого
            праведного Иоанна Русского, который является небесным покровителем
            молодежного миссионерского общества в приходе.
            <div>
              При приходе учреждена Православная гимназия во имя святых
              равноапостольных Кирилла и Мефодия.
            </div>
            <div></div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Богдана Хмельницкого, 74.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Казанской иконы */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Казанской иконы Божией Матери</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh7}
              width={350}
              alt="Храм в честь Казанской иконы Божией Матери"
              title="Храм в честь Казанской иконы Божией Матери"
            />
          </div>
          <div class={"inf-4"}>
            Община храма в честь Казанской иконы Божией Матери зарегистрирована
            16 апреля 1997 года. В 2000 году неподалеку от микрорайона
            «Березовое» в Первомайском районе был выделен участок и отслужен
            молебен на начало строительства будущего храма. В 2007 году была
            освящена временная деревянная Казанская церковь.
            <div>
              В 2012 году на строящийся кирпичный храм водрузили главный купол с
              главкой и крестом, в 2019 году установили резной иконостас, тогда
              же в новом храме прошли первые службы. В январе 2020 года здание
              новопостроенного храма официально принято в эксплуатацию.
            </div>
            <div></div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Одоевского 32.
            </p>
          </div>
        </table>
      </div>

      {/* св. Георгия */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святого великомученика Георгия Победоносца </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh5}
              width={350}
              alt="Храм во имя святого великомученика Георгия Победоносца "
              title="Храм во имя святого великомученика Георгия Победоносца "
            />
          </div>
          <div class={"inf-4"}>
            Решение о строительстве храма в микрорайоне "Северный" было принято
            в 2008 году. Было решено сначала построить небольшой храм-часовню, с
            тем чтобы в ближайшее время начать совершение богослужений, а по
            завершении строительства основного храма переоборудовать малый храм
            в крестильный. В ноябре 2008 года Распоряжением по Новосибирской
            епархии было благословлено именовать строящийся храм во имя святого
            великомученика Георгия Победоносца. В октябре 2009 года было
            совершено освящение закладного камня в основание храма.
            <div>
              
              В 2015 году было начато строительство цокольного этажа. И с этого
              времени, ввиду отсутствия денежных средств, само строительство
              было приостановлено. В 2019 году по рекомендации Владыки Никодима
              было решено строить деревянный храм из оцилиндрованного бруса. 19
              сентября 2019 года митрополитом Новосибирским и Бердским Никодимом
              был совершен чин основания деревянного храма. А уже 23 ноября 2019
              года владыкой Никодимом были освящены накупольные кресты на
              строящийся храм и купола. Храм был освящен и открыт для прихожан 5
              января 2020 года.
            </div>
            <div></div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Оптическая 71.
            </p>
          </div>
        </table>
      </div>

      {/* св. Николая */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святителя Николая Чудотворца </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh3}
              width={300}
              alt="Храм во имя святеля Николая "
              title="Храм во имя святеля Николая"
            />
          </div>
          <div class={"inf-4"}>
            В середине 1920-х годов верующие села Новолуговое решили построить
            собственный храм. Около 1926 года храм был уже построен и освящен во
            имя Святителя и Чудотворца Николая.
            <div>
              С 1935 по май 1937 года настоятелем Никольского храма был
              архимандрит Сергий (Скрипальщиков), который был арестован и
              расстрелян в 1937 году в Новосибирске вместе с архиепископом
              Сергием (Васильковым).
            </div>
            <div>
              Постановлением Новосибирского облисполкома от 16 марта 1938 года
              Никольская церковь села Новолугового была закрыта.
            </div>
            <div>
              После Великой Отечественной войны, по благословению архиепископа
              Варфоломея (Городцева), группа верующих обратилась с ходатайством
              об открытии храма. Ходатайство это, после ряда проволочек, было
              удовлетворено. Первым настоятелем в послевоенные годы стал
              протоиерей Василий Стариков. Приход был довольно большим: верующие
              не только Новолугового, но и станции Инская, села Барышево и
              других населенных пунктов юга новосибирской области стали ее
              прихожанами.
            </div>
            <div>
              В 1955 году в храме перебрали пол, устроили притвор, а в конце
              1950-х начале 1960-х годов храм был расписан художником Николаем
              Клюковкиным. В настоящее время это один из старейших храмов города
              Новосибирска, который был построен в годы гонений и пережил их.
            </div>
            <p>
              <b>Адрес:</b> с. Новолуговое, ул. Первомайская, 3.
{" "}
            </p>
          </div>
        </table>
      </div>


       {/* Архангела Михаила 2*/}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя Михаила Архангела</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh15}
              width={300}
              alt="Храм во имя святого апостола Андрея Первозванного "
              title="Храм во имя святого апостола Андрея Первозванного "
            />
          </div>
          <div class={"inf-4"}>
            Территория, где расположен храм во имя Михаила Архангела,
            первоначально принадлежала деревне Усть-Иня. В 1922 году в ней был
            построен храм во имя Михаила Архангела. В 1939 году городские власти
            церковь закрыли, в помещении был устроен кинотеатр «Заря». В конце
            1950-х годов обветшавшее здание снесли, а спустя несколько лет
            немного в стороне построили дом культуры с таким же названием. В
            1994 году был возрожден приход во имя Михаила Архангела. Для
            обустройства церкви ему передали здание бывшего дома культуры,
            находившееся к этому времени уже в аварийном состоянии.
            <p>
              <b>Адрес:</b> ул. Большевистская, 229.
            </p>
          </div>
        </table>
      </div>



      {/* Храм святых Новомучеников и Исповедников Церкви Русской */}
      {/* <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм святых Новомучеников и Исповедников Церкви Русской</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh16}
              width={300}
              alt="Храм святых Новомучеников и Исповедников Церкви Русской"
            />
          </div>
          <div class={"inf-4"}>
            Храм был освящен в 2004 году и входит в состав монастыря в честь
            Новомучеников и Исповедников Церкви Русской, образованного 6 октября
            1999 года.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Немировича-Данченко 120/1.{" "}
            </p>
          </div>
        </table>
      </div> */}


      {/* Храм иконы Божией Матери «Неупиваемая Чаша» */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм иконы Божией Матери «Неупиваемая Чаша» <div>при Епархиальном реабилитационном центре</div></p>
          </div>
          <div class="church-1-image">
            <img
              src={hh17}
              width={300}
              alt="Храм иконы Божией Матери «Неупиваемая Чаша»"
            />
          </div>
          <div class={"inf-4"}>
            Храм располагается в здании бывшего кинотеатра «Спутник» в
            Заельцовском районе Новосибирска. 28 декабря 2014 года 
            состоялось освящение храма и была отслужена первая Божественная Литургия.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Ереванская 14/1.{" "}
            </p>
          </div>
        </table>
      </div>


      {/* Часовня во имя Святителя Николая Чудотворца */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Часовня во имя Святителя и Чудотворца Николая</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh18}
              width={300}
              alt="Часовня во имя Святителя Николая Чудотворца"
            />
          </div>
          <div class={"inf-4"}>
            Располагается на Красном проспекте и является одним из символов
            Новосибирска. С незначительными изменениями размеров и пропорций
            воспроизводит облик утраченной часовни Святителя Николая Чудотворца,
            которая была возведена в ознаменование 300-летия царствования Дома
            Романовых. Торжественный молебен и закладка часовни состоялись 20
            июля 1914 года. Строительство велось на пожертвования
            благотворителей и горожан Ново-Николаевска, на завершающем этапе
            ввиду недостатка денежных средств и рабочих рук впервые в истории
            города был использован метод «народной стройки». Освящение часовни
            состоялось 6 декабря 1915 года, в день памяти Святителя Николая
            Чудотворца. 9 ноября 1929 года Новосибирский окрисполком, «учитывая
            пожелания трудящихся масс и считаясь с благоустройством города»,
            одобрил постановление Новосибирского горсовета о сносе часовни.
            Разрушение святыни началось 29 января 1930 года. В начале 1990-х
            годов, в ходе подготовки к празднованию 100-летия Новосибирска, было
            принято решение о восстановлении часовни. В юбилейном 1993 году
            строительство часовни во имя Святителя Николая было завершено.
            <p>
              <b>Адрес:</b> г. Новосибирск, Красный проспект 17а{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Храм во имя преподобного и благоверного князя Олега Брянского */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя преподобного и благоверного князя Олега Брянского</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh19}
              width={300}
              alt="Храм во имя преподобного и благоверного князя Олега Брянского"
            />
          </div>
          <div class={"inf-4"}>
            Храм возведен на территории Октябрьского района и освящен 24 июня
            2013 года. Через несколько дней, 28 июня, состоялось архиерейское
            освящение храма, которое совершил Владыка Тихон, митрополит
            Новосибирский и Бердский. Огромный вклад в строительство внес Олег
            Васильевич Шабуневич, пожертвовавший земельный участок, на котором
            построен храм, и принявший деятельное участие в его строительстве и
            благоустройстве.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Лескова 131.{" "}
            </p>
          </div>
        </table>
      </div>



     

      {/* <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>
              Храм во имя святых благоверных князей Петра и Февронии Муромских
            </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh21}
              width={300}
              alt="Храм во имя святых благоверных князей Петра и Февронии Муромских"
            />
          </div>
          <div class={"inf-4"}>
            Расположен на территории жилмассива «Южно-Чемской» Кировского района
            Новосибирска. Первоначально на первом этаже жилого здания (ул.
            Петухова 99/2) была устроена и 17 сентября 2017 года освящена
            часовня в честь святых благоверных князя Петра и княгини Февронии,
            Муромских чудотворцев. В 2020 году началось строительство
            деревянного храма. 9 декабря 2021 года состоялось освящение куполов
            и крестов в навершие строящегося храма. 27 апреля 2022 года церковь
            была освящена великим чином. В бывшем помещении часовни
            расположились детская воскресная школа, библиотека, клуб «Сретение».
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Бронная 36/1.{" "}
            </p>
          </div>
        </table>
      </div> */}



      {/* Храм во имя преподобного Амвросия Оптинского */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя преподобного Амвросия Оптинского </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh22}
              width={300}
              alt="Храм во имя преподобного Амвросия Оптинского"
            />
          </div>
          <div class={"inf-4"}>
            В 1992 году на Затулинском жилмассиве, в Кировском районе
            Новосибирска, был создан православный приход во имя преподобного
            Амвросия Оптинского. В 2005 году было построено двухэтажное
            кирпичное приходское сооружение с храмовым помещением — однопрестольной
            церковью с большим арочным центральным входом. В 2007 году возле
            него появился деревянный храм, который освятили в честь Успения
            Пресвятой Богородицы.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Громова 19, к. 1.{" "}
            </p>
          </div>
        </table>
      </div>




     
      {/* Храм в честь Благовещения Пресвятой Богородицы */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Благовещения Пресвятой Богородицы </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh24}
              width={300}
              alt="Храм в честь Благовещения Пресвятой Богородицы"
            />
          </div>
          <div class={"inf-4"}>
            29 марта 2000 года началось строительство Благовещенского храма на
            Шлюзе, в Советском районе Новосибирска. 30 марта 2001 года прошло
            малое освящение храма, а 1 апреля в нем прошла первая служба.
            <p>
              <b>Адрес:</b>г. Новосибирск, ул. Русская 8а.{" "}
            </p>
          </div>
        </table>
      </div>



      {/* Храм во имя мученика Вонифатия Тарсийского  */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя мученика Вонифатия Тарсийского </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh25}
              width={300}
              alt="Храм во имя мученика Вонифатия Тарсийского"
            />
          </div>
          <div class={"inf-4"}>
            В январе 2008 года приходу храма в честь иконы Божией Матери
            «Знамение-Абалацкая» администрацией Новосибирской области было
            передано нежилое одноэтажное здание под организацию часовни. Силами
            прихода и благотворителей был проведен неотложный ремонт и
            подготовлено помещение для совершения богослужений. 15 сентября 2012
            года храм был освящен во имя мученика Вонифатия Тарсийского. В
            ноябре 2013 года недвижимое имущество храма передано из
            собственности Знаменского прихода в собственность прихода мученика
            Вонифатия.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Столетова 17а.{" "}
            </p>
          </div>
        </table>
      </div>



      {/* Храм в честь Рождества Пресвятой Богородицы  */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Рождества Пресвятой Богородицы </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh29}
              width={300}
              alt="Храм в честь Рождества Пресвятой Богородицы"
            />
          </div>
          <div class={"inf-4"}>
            Является первым, малым храмом прихода в честь Рождества Пресвятой
            Богородицы в «нижней зоне» Академгородке. Освящение креста, земли и
            закладного камня в основание храма состоялось 15 января 1995 года. 6
            января 1996 года деревянный храм был освящен, и на следующий день в
            нем прошла первая служба.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Шатурская 2а.{" "}
            </p>
          </div>
        </table>
      </div>

      {/* Храм в честь Троицы Живоначальной  */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Троицы Живоначальной </p>
          </div>
          <div class="church-1-image">
          <div class="church-1-image">
            <img
              src={hh28}
              width={300}
              alt="Храм в честь Троицы Живоначальной"
            />
          </div>
          </div>
          <div class={"inf-4"}>
            Является вторым храмом Богородице-Рождественского прихода в
            Академгородке. В 1996 году был построен небольшой деревянный храм в
            честь Рождества Пресвятой Богородицы, и в том же году рядом заложили
            новый каменный храм. К концу 2001 года он был построен и 19 декабря
            освящен в честь Троицы Живоначальной.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Шатурская 2а.{" "}
            </p>
          </div>
        </table>
      </div>



      {/* Храм во имя преподобного Серафима Саровского  */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя преподобного Серафима Саровского </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh30}
              width={300}
              alt="Храм во имя преподобного Серафима Саровского"
            />
          </div>
          <div class={"inf-4"}>
            Приход во имя преподобного Серафима Саровского основан в ноябре 1995
            года. Временный храм разместился в помещении бывшего детского сада.
            Первая Божественная Литургия была совершена в нем 11 марта 1996
            года. 1 августа 2016 года состоялось освящение закладного камня,
            креста и места под строительство нового каменного храма во имя
            преподобного Серафима Саровского. Восточным фасадом строящаяся
            церковь примыкает к действующему храму.
            <p>
              <b>Адрес:</b> г. Новосибирск, 1-й переулок Пархоменко 3.{" "}
            </p>
          </div>
        </table>
      </div>

      
      {/* Храм в честь Успения Пресвятой Богородицы */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Успения Пресвятой Богородицы </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh32}
              width={300}
              alt="Храм в честь Успения Пресвятой Богородицы"
            />
          </div>
          <div class={"inf-4"}>
            Деревянный храм в честь Успения Пресвятой Богородицы на Затулинском
            жилмассиве, в Кировском районе Новосибирска, освящен 23 июня 2007
            года. Входит в единый храмовый комплекс на одной территории
            совместно с храмом во имя преподобного Амвросия Оптинского.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Громова 19.{" "}
            </p>
          </div>
        </table>
      </div>


      {/* Храм в честь Успения Пресвятой Богородицы */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Успения Пресвятой Богородицы </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh33}
              width={300}
              alt="Храм в честь Успения Пресвятой Богородицы"
            />
          </div>
          <div class={"inf-4"}>
            В 1925 году на городском кладбище Новосибирска была построена
            деревянная церковь в честь Успения Пресвятой Богородицы. В 1930-е
            годы, в ходе гонений на Русскую Православную Церковь, все храмы
            города были закрыты, и лишь одна Успенская кладбищенская церковь
            продолжала действовать. С приездом в 1943 году архиепископа
            (впоследствии митрополита) Варфоломея (Городцева; †1956) короткое
            время она выполняла функцию кафедрального собора. В 1962 году,
            несмотря на протесты верующих, церковь была ликвидирована. В 1970-е
            годы на месте бывшего кладбища открылся парк отдыха «Березовая
            роща». В 1995 году городские власти передали недостроенное здание
            кафе на территории «Березовой рощи» в ведение Новосибирской епархии.
            Был разработан проект переоборудования здания под храм. 27 июня 1999
            года храм был освящен в честь Успения Пресвятой Богородицы.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Гоголя 179.{" "}
            </p>
          </div>
        </table>
      </div>




      {/* Храм в честь иконы Божией Матери «Утоли моя печали» */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь иконы Божией Матери «Утоли моя печали» </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh34}
              width={300}
              alt="Храм в честь иконы Божией Матери «Утоли моя печали» "
            />
          </div>
          <div class={"inf-4"}>
            15 июня 1997 года был освящен закладной камень в основание будущего
            храма у ворот Клещихинского кладбища Новосибирска. В июне 2006 года
            состоялось освящение нижнего храма во имя святого мученика Уара.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Хилокская 10/1.{" "}
            </p>
          </div>
        </table>
      </div>


      {/* Храм в честь Всех святых */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм в честь Всех святых </p>
          </div>
          <div class="church-1-image">
            <img src={hh35} width={300} alt="Храм в честь Всех святых " />
          </div>
          <div class={"inf-4"}>
            Храм в честь Всех святых устроен в перестроенном кирпичном здании
            бывшего кинотеатра «Луч» в Кировском районе Новосибирска. Освящен 31
            декабря 1993 года. 6 октября 1999 года решением Священного Синода
            приход во имя Всех святых преобразован в монастырь святых
            Новомучеников Российских (ныне святых Новомучеников и Исповедников
            Церкви Русской), который стал первым монастырем в черте города
            Новосибирска. В настоящее время монастырский храм в честь
            Новомучеников и Исповедников Церкви Русской находится на пересечении
            улиц Немировича-Данченко и Ватутина.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Новогодняя 24.{" "}
            </p>
          </div>
        </table>
      </div>


      {/* Храм в честь Новомучеников и Исповедников Церкви Русской */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>
              Храм в честь
              Новомучеников и Исповедников Церкви Русской{" "}
            </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh36}
              width={300}
              alt="Новомучеников и Исповедников Церкви Русской "
            />
          </div>
          <div class={"inf-4"}>
            Освящение места под строительство храма на пересечении улиц
            Немировича-Данченко и Ватутина состоялось 29 августа 1994 года. В
            апреле 2003 года был поднят купол. 25 июня 2005 года храм был
            освящен в честь святых Новомучеников Российских (ныне святых
            Новомучеников и Исповедников Церкви Русской). Храм входит в состав
            монастыря в честь Новомучеников и Исповедников Церкви Русской,
            образованного 6 октября 1999 года. Жилой корпус монастыря
            разместился в здании бывшего клуба Новосибирского оловокомбината
            (ул. Мира 62а/1). Домовой храм освящен во имя преподобного Серафима
            Саровского.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Немировича-Данченко 120/1.{" "}
            </p>
          </div>
        </table>
      </div>



     
      {/* Храм во имя преподобной Ефросинии Полоцкой */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя преподобной Евфросинии Полоцкой</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh38}
              width={300}
              alt="Храм во имя преподобной Евфросинии Полоцкой "
            />
          </div>
          <div class={"inf-4"}>
            В 2002 году на территории МЖК «Восточный» сооружен храм во имя
            преподобной Евфросинии Полоцкой — святой покровительницы
            белорусского народа и покровительницы женского монашества на Руси.
            Строительство осуществлялось при помощи белорусского землячества
            Новосибирска. В 2002 году, во время празднования Дня славянской
            письменности и культуры, состоялось освящение храма архиепископом
            Новосибирским и Бердским Тихоном и епископом Борисовским Иоанном
            (ныне архиепископ Брестский и Кобринский) — представителем
            Белорусского Экзархата Русской Православной Церкви. Храм является
            частью храмового комплекса, на территории которого запланировано
            возведение Православного культурного центра.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Лазурная 26.{" "}
            </p>
          </div>
        </table>
      </div>


      {/* Храм во имя святого великомученика Георгия Победоносца
      на территории 39-й гвардейской ракетной Глуховской дивизии */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святого великомученика Георгия Победоносца
на территории 39-й гвардейской ракетной Глуховской дивизии</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh20}
              width={300}
              alt="Храм во имя святого великомученика Георгия Победоносца
              на территории 39-й гвардейской ракетной Глуховской дивизии "
            />
          </div>
          <div class={"inf-4"}>
            Храм распологается на территории 39-й гвардейской ракетной Глуховской дивизии. 
            Храм был построен за 10 месяцев, его строительство было приурочено к 75-летней 
            годовщине Великой Победы.  
           <div> 29 мая 2021 года митрополит Новосибирский и Бердский Никодим
            совершил чин освящения храма. Храм освящен
            во имя святого великомученика Георгия Победоносца, покровителя русского воинства.</div>
           
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Солидарности, 59а к1.
            </p>
          </div>
        </table>
      </div>



     
      {/* Храм во имя святого праведного Иоанна Кронштадтского в п. Пашино */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святого праведного Иоанна Кронштадтского в п. Пашино</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh16}
              width={300}
              alt="Храм во имя святого праведного Иоанна Кронштадтского в п. Пашино "
            />
          </div>
          <div class={"inf-4"}>
          Православная община в поселке Пашино образовалась в середине 90-х годов.
          27 сентября 1997 года был установлен и освящен закладной камень будущего храма 
          во имя святого праведного Иоанна Кронштадтского. В 1998 году в Пашино был перевезен 
          бывший деревянный крестильный храм Александро-Невского собора. В праздник Светлой 
          Христовой Пасхи 1998 года совершено первое Богослужение.
          <div>В 1999 началось строительство нового храма. 2 января 2000 года, на 
            престольный праздник прихода — День блаженной кончины святого праведного Иоанна 
            Кронштадтского, состоялось малое освящение храма. В 2004 году  
            были освящены и подняты на колокольню шесть колоколов.
            Из бревен прежнего храма было сооружено здание приходской школы. 
            В 2015 году состоялось великое освящение храма.</div>
            <p>
              <b>Адрес:</b> г. Новосибирск, п. Пашино, ул. Магистральная, 9.{" "}
            </p>
          </div>
        </table>
      </div>

          
      {/* Храм во имя святых мучениц Веры, Надежды, Любови и матери их Софии в п. Пашино */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>Храм во имя святых мучениц Веры, Надежды, Любови и матери их Софии в п. Пашино</p>
          </div>
          <div class="church-1-image">
            <img
              src={hh21}
              width={300}
              alt="Храм во имя святых мучениц Веры, Надежды, Любови и матери их Софии в п. Пашино "
            />
          </div>
          <div class={"inf-4"}>
          30 сентября 2023 года митрополит Новосибирский и Бердский Никодим совершил освящение храма во имя святых 
          мучениц Веры, Надежды, Любови и матери их Софии в микрорайоне Пашино г. Новосибирска.
          <div>Храм находится на территории Северного кладбища г. Новосибирска.  </div>
            <p>
              <b>Адрес:</b> г. Новосибирск, п. Пашино, ул. Новоуральская, 128.{" "}
            </p>
          </div>
        </table>
      </div>

      <p></p>  

     
      {/* Храм в честь Казанской иконы Божией Матери в Заельцовском бору */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>
              Храм в честь Казанской иконы Божией Матери в Заельцовском бору
            </p>
          </div>
          <div class="church-1-image">
            <img
              src={hh41}
              width={300}
              alt="Храм в честь Казанской иконы Божией Матери в Заельцовском бору "
            />
          </div>
          <div class={"inf-4"}>
            Сооружен в 2012 году на территории бывшего санатория «Березка» в
            Заельцовском бору. На храме прикреплена мраморная доска с надписью:
            «Сей святой храм во имя Казанской иконы Пресвятой Богородицы создан
            в память о поколении русских людей, работников Новосибирского
            электровакуумного завода, завоевавших на полях сражений и в
            заводских цехах Победу в Великой Отечественной войне».
            <p>
              <b>Адрес:</b> г. Новосибирск, Лесное шоссе 1а.{" "}
            </p>
          </div>
        </table>
      </div>


      <p></p>  

     
      {/* Храм в св. Василия Острожского */}
      <div class="church-1">
        <table>
          <div class="church-1-title">
            <p>
            Храм во имя святителя Василия Острожского
            </p>
          </div>
          <table class="church-1-image">
          <tr><div class="church-1-image">
            <img
              src={hh23}
              width={300}
              alt="Храм во имя святителя Василия Острожского "
            />
          </div></tr>
         <tr><div class="church-1-image">
            <img
              src={hh26}
              width={300}
              alt="Храм во имя святителя Василия Острожского "
            />
          </div></tr>
          </table>
          <div class={"inf-4"}>
          В 2017 году депутат Законодательного собрания Новосибирской области Виктор Васильевич 
          Кушнир обратился к Митрополиту Новосибирскому и Бердскому Тихону с предложением открыть 
          на территории микрорайона Балластный карьер в Ленинском районе православный храм. 
          Администрация готова была выделить помещение для храма в двухэтажном доме в центре поселения. 
          Владыка поддержал предложение и в марте 2017 года назначил ответственным за организацию 
          нового прихода священника.
          <div>Начались ремонтные и другие работы по устроению в помещении храма.
            30 сентября 2018 года храм был освящен.</div>
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Клубная, 96.{" "}
            </p>
          </div>
        </table>
      </div>
      <p></p>


        {/* Храм в честь св. Пантелеимона в Доме ветеранов */}
        <div class="church-1">
        <table>
          <div class="church-1-title">
            
            Домовый храм во имя святого великомученика и целителя Пантелеимона
            <div>в Новосибирском Доме ветеранов</div>
 
            
          </div>
          <div class="church-1-image">
            <img
              src={hh11}
              width={350}
              alt="Домовый храм во имя святого великомученика и целителя Пантелеимона "
            />
          </div>
          <div class={"inf-4"}>
          В 1992 году, по благословению Преосвященнейшего епископа Тихона и при содействии директора 
          в Доме ветеранов был создан приход во имя святого великомученика 
          и целителя Пантелеимона. Администрация выделила помещение на первом этаже главного корпуса 
          для обустройства в нем храма и помогла с ремонтом помещения. Освящение храма состоялось 
          в том же году на праздник Святой Пасхи. Владыка Тихон благословил приход крестом, который 
          с тех пор является одной из главных святынь храма, бережно хранимой и особо почитаемой прихожанами.
            <p>
              <b>Адрес:</b> г. Новосибирск, ул. Жуковского, 98 .{" "}
            </p>
          </div>
        </table>
      </div>


      <p></p>
                    <div class ={"inf-8"}>Раздел дополняется...</div> <p></p>

      <div className="all_ep" style={{ filter: isActive ? "blur(150px)" : "" }}>
        {" "}
      
      </div>

      <Link to="/">
        <div class="BiExit">
          <BiExit />
        </div>
      </Link>
    </div>
  );
}

export default Ep;
